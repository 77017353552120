<template>
    <div>
        <card title="Filter" class="border-radius mb-6" bodyClass="overflow-visible" title-no-border>
            <div slot="tools" class="flex">
                <button @click="reset">
                    <fa class="mr-1" :icon="['fal','undo']" />Reset
                </button>
            </div>

            <modal-field label="Amount of days" class="flex w-1/3">
                <date-picker v-model="amount" :disable-past="false" :disabled-start="currentDate" />
            </modal-field>
        </card>

        <div v-if="!isLoading" class="flex flex-wrap">
            <div 
                v-for="(item, key, i) in value" 
                :key="i" 
                :class="className(i)"
                class="mb-6"
            >
                <chart-block
                    :value="item"
                    :key-value="key"
                />
            </div>
        </div>

        <div v-else class="flex flex__justify-center">
            <fa class="brand-primary text-3xl fa-spin ml-1" :icon="['fad','spinner-third']" />
        </div>  
    </div>
</template>

<script>
import axios from 'axios';
import moment from 'moment';

import ChartBlock from './ChartBlock.vue';
import DatePicker from '~/components/DatePicker'

export default {
    name: 'AdminDashboard',

    components: {
        ChartBlock,
        DatePicker,
    },

    metaInfo() {
        return { title: "Dashboard" };
    },

    data: () => ({
        value: null,

        isLoading: true,

        amount: moment().subtract(7, 'days'),
    }),

    computed: {
        diffAmount() {
            return moment(new Date()).diff(moment(this.amount), 'days');
        },

        query() {
            if (!this.diffAmount) {
                return ''
            }

            return `?period=${this.diffAmount}`;
        },

        currentDate() {
            return moment();
        },
    },

    methods: {
        className(index) {
            const result = [];

            if (this.diffAmount >= 100) {
                return ['w__100-p'];
            }

            if (index % 2 === 0) {
                result.push('pr-3');
            }

            if (index % 2 != 0) {
                result.push('pl-3');
            }

            result.push('w-1/2');

            return result;
        },

        reset() {
            this.amount = moment().subtract(7, 'days');
        },
        
        async fetch() {
            this.isLoading = true;

            const result = await axios.get(this.$apiUrl.admin.dashboard + this.query);

            this.value = result.data.data;

            this.isLoading = false;
        },
    },

    watch: {
        amount :{
            handler() {
                this.fetch();
            },

            deep: true,
        },
    },

    async created() {
        await this.fetch();
    },
}
</script>